<template>
  <div id="about-us" class="container pt-5">
    <h1 class="mb-5">Contact Us</h1>
    <p>For all sales enquiries, or to discuss how we can help your business, contact us.</p>
    <p>
      You can always
      <router-link :to="{ name: 'BookDemo' }">book a demo</router-link> of our application.
    </p>

    <div class="container row p-0 mb-5 pb-5">
      <div class="col-6 px-0">
        <ContactForm />
      </div>
      <div class="offset-md-1 col-5 px-0">
        <div class="mb-5" v-for="contactInfo in contactInfos" :key="contactInfo.telno">
          <h4 style="letter-spacing: -1px">
            {{ contactInfo.name }}
          </h4>
          <div class="mb-2">
            <a :href="`tel:${contactInfo.telno}`"
              ><img src="/assets/img/icon-phone2.svg" class="icon" style="margin-right: 24px" alt="icon" />
              <span>{{ contactInfo.telno }}</span></a
            >
          </div>
          <div>
            <a :href="`mailto:${contactInfo.email}`"
              ><img src="/assets/img/icon-email2.svg" class="icon" style="margin-right: 24px" alt="icon" />
              <span>{{ contactInfo.email }}</span></a
            >
          </div>
        </div>
        <div class="my-5">
          <h4>Technical enquiries:</h4>
          <a :href="`mailto:support@iappraise.net.au`"
            ><img src="/assets/img/icon-email2.svg" class="icon" style="margin-right: 24px" alt="icon" />
            <span>support@iappraise.net.au</span></a
          >
        </div>
        <div class="my-5">
          <h4>Administration enquiries:</h4>
          <a :href="`mailto:admin@iappraise.net.au`"
            ><img src="/assets/img/icon-email2.svg" class="icon" style="margin-right: 24px" alt="icon" />
            <span>admin@iappraise.net.au</span></a
          >
        </div>
        <div class="my-5">
          <h4>Where to find us:</h4>
          <p>1/93 Glen Osmond Road, Eastwood, 5063, South Australia.</p>
        </div>
      </div>
    </div>
  </div>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.6233645270504!2d138.62139742921386!3d-34.9442387987733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cebe3070ffff%3A0x25875c9263a87fb8!2siAppraise!5e0!3m2!1sen!2smy!4v1600327500945!5m2!1sen!2smy"
    height="450"
    frameborder="0"
    style="width: 100%"
    allowfullscreen
  ></iframe>
</template>

<script>
import "bootstrap";
import ContactForm from "@/components/Forms/ContactForm.vue";

export default {
  name: "ContactUs",
  components: { ContactForm },
  data: function () {
    return {
      contactInfos: [
        {
          name: "Stephen Gribble",
          telno: "0418 263 166",
          email: "stephen@iappraise.net.au"
        },
        {
          name: "George Grifsas",
          telno: "0468 469 180",
          email: "george@iappraise.net.au"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/buttons.scss";
@import "@/scss/_variables.scss";

h1,
h2,
h3,
h4 {
  font-family: "sf_pro_displaybold";
  font-style: normal;
  font-weight: bold;
}

h1,
h2 {
  font-size: 64px;
  line-height: 76px;
}

p {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-family: "sf_pro_displayregular";
}
</style>
