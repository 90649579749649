<template>
  <div style="max-width: 600px">
    <form id="contact-form" @submit.prevent="sendContactForm" method="POST" ref="contactForm">
      <div class="input-field">
        <input type="text" v-model="form.name" name="name" placeholder="Name" required />
      </div>

      <div class="input-field">
        <input type="email" v-model="form.email" name="email" placeholder="Email" required />
      </div>

      <div class="input-field">
        <input type="tel" v-model="form.phone_number" name="phone_number" placeholder="Contact Number" required />
      </div>

      <div class="input-field" autocomplete="off">
        <textarea
          autocomplete="off"
          rows="4"
          cols="50"
          v-model="form.message"
          name="message"
          placeholder="Message/Questions"
          required
        />
      </div>

      <div style="position: relative; text-align: center">
        <button type="submit" :disabled="btnDisabled" id="submit-btn">
          <span v-if="!btnDisabled"> Send </span>
          <div v-else class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bookDemo } from "@/api/BookDemo.js";

export default {
  name: "ContactForm",
  methods: {
    sendContactForm() {
      let self = this;
      this.btnDisabled = true;
      bookDemo(this.form)
        .then(data => {
          alert(data.data.detail);
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
        })
        .finally(() => {
          self.btnDisabled = false;
        });
    }
  },
  data: function () {
    return {
      btnDisabled: false,
      form: {
        name: "",
        email: "",
        phone_number: "",
        message: ""
      }
    };
  }
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/_variables.scss";

.input-field {
  height: unset;
  border-bottom: 0;
  & input {
    height: 60px;
  }
}

//contact-form
.home-contact {
  p {
    font-size: 24px;
  }

  .contacts {
    .icon {
      vertical-align: middle;
    }
    a {
      display: inline-block;
      margin-bottom: 5px;
      color: #000;
      text-decoration: none;
    }
  }

  #contact-form {
    .input-field > input,
    textarea {
      font-family: "sf_pro_displayregular";
      outline: none;
      display: block;
      background: rgba(243, 243, 243, 1);
      width: 100%;
      margin: 30px 0;
      border: 0;
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;
      padding: 20px;
      color: grey;
      font-size: 17px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &::placeholder {
        color: black;
      }
    }

    #submit-btn {
      font-family: "sf_pro_displayregular";
      font-size: 20px;
      border: 0;
      width: 100%;
      background-color: black;
      color: $COLOUR_YELLOW;
      padding: 20px 50px;
      cursor: pointer;
    }
  }
}

#contact-form {
  .input-field > input,
  textarea {
    font-family: "sf_pro_displayregular";
    outline: none;
    display: block;
    background: rgba(243, 243, 243, 1);
    width: 100%;
    margin: 30px 0;
    border: 0;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    padding: 20px;
    color: grey;
    font-size: 17px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &::placeholder {
      color: black;
    }
  }

  #submit-btn {
    font-family: "sf_pro_displayregular";
    font-size: 20px;
    border: 0;
    width: 100%;
    background-color: black;
    color: $COLOUR_YELLOW;
    padding: 20px 50px;
    cursor: pointer;
  }
}
</style>
